import styled, { css } from 'styled-components'
import { darken, lighten } from 'polished'
import { Link } from 'gatsby'
import { breakpoints } from 'src/utils/styles/global-styles'
import { GatsbyImage } from 'gatsby-plugin-image'

export const Section = styled.div`
  background-color: ${(props) => props.theme.cream};
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px;
  @media (max-width: ${breakpoints.xl}px) {
    flex-direction: column-reverse;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding-top: ${(props) => (props.topPadding ? '20px' : '0px')};
  }
`

export const VideoContainer = styled.div`
  position: relative;
  width: 70%;
  height: 50%;
  @media (max-width: ${breakpoints.xl}px) {
    margin-left: 0px;
  }
  @media (max-width: ${breakpoints.l}px) {
    min-width: 50%;
    width: 100%;
    margin-left: 10%;
  }
`

export const HeroVideo = styled.video`
  width: 100%;
  max-height: 100%;
  border-radius: 56px 0px 0px 56px;
  @media (max-width: ${breakpoints.xl}px) {
    border-radius: 56px;
    margin-bottom: 10px;
  }
  @media (max-width: ${breakpoints.l}px) {
    border-radius: 40px 0px 0px 40px;
    margin-bottom: 0px;
  }
`

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: normal;
  margin: 0;
  flex-shrink: 1.7;
  font-family: 'FilsonProBook';
  padding: 0% 5%;
  width: 80%;
  @media (max-width: ${breakpoints.xl}px) {
    flex-shrink: 1;
    align-items: center;
    margin-top: 20px;
  }
  @media (max-width: ${breakpoints.l}px) {
    margin-top: 1rem;
    max-width: 80vw;
    margin-bottom: 30px;
    .yotpo-sr-bottom-line-summary {
      margin: 20px 0;
    }
  }
  @media (max-width: ${breakpoints.m}px) {
    max-width: 100vw;
    width: 100%;
    .yotpo-sr-bottom-line-summary {
      margin: 20px 0px 10px;
    }
  }
  @media (max-width: ${breakpoints.s}px) {
    margin-top: 0px;
    margin-bottom: 10px;
    padding: auto;
  }
`

export const CenteredContent = styled.p`
  text-align: center;
  display: ${(props) => (props.hide ? 'none' : 'block')};
`

export const CenteredOnMobile = styled.div`
  text-decoration: none;
  margin-top: 50px;
  @media (max-width: ${breakpoints.l}px) {
    margin-top: 40px;
    text-align: center;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin-top: 30px;
  }
`

const linkStyle = css`
  font-family: 'FilsonPro';
  background-color: ${(props) => props.theme.coral};
  color: white;
  text-decoration: none;
  border-radius: 28px;
  border: none;
  font-size: 18px;
  padding: 15px 30px;
  line-height: 1.28;
  letter-spacing: 0.09px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => darken(0.1, props.theme.coral)};
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.1, props.theme.coral)}`};
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0.08px;
  }
`

export const BuyNowLink = styled(Link)`
  ${linkStyle}
  background-color: ${(props) => props.theme.coral};
  &:hover {
    cursor: pointer;
    background-color: ${(props) => darken(0.1, props.theme.coral)};
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.1, props.theme.coral)}`};
  }
`

export const BuyNowAnchor = styled.a`
  ${linkStyle}
`

export const Text = styled.h1`
  font-family: 'P22MackinacProBook';
  font-size: 38px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.19px;
  text-align: left;
  margin: 20px 0px 10px;
  color: ${(props) => props.theme.grayDark};
  width: 100%;
  @media (max-width: 1450px) {
    font-size: 34px;
  }
  @media (max-width: 1250px) {
    font-size: 30px;
  }
  @media (max-width: ${breakpoints.xl}px) {
    text-align: center;
    font-size: 32px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 0.15px;
  }
  @media (max-width: 400px) {
    font-size: 21px;
    line-height: 30px;
    letter-spacing: 0.15px;
  }
`

export const SubHeader = styled.h3`
  font-family: 'FilsonProBook';
  color: ${(props) => props.theme.grayDark};
  font-size: 28px;
  margin: 0px 0px 30px;
  @media (max-width: ${breakpoints.xl}px) {
    font-size: 24px;
  }
  @media (max-width: ${breakpoints.l}px) {
    text-align: center;
    font-size: 20px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    margin: -15px 0px 5px;
  }
`

export const Item = styled.div`
  display: flex;
  align-items: flex-start;
`
export const ListItem = styled.p`
  font-size: 22px;
  letter-spacing: 0.04px;
  margin: 2px 0px 2px 5px;
  font-family: 'filsonProBook';
  @media (max-width: ${breakpoints.l}px) {
    font-size: 18px;
  }
  @media (max-width: 400px) {
    font-size: 15px;
  }
  @media (max-width: ${breakpoints.s}px) {
    text-align: center;
  }
`
export const CheckMark = styled(GatsbyImage)`
  margin-top: 10px;
  min-width: 20px;
  width: 20px;
  @media (max-width: ${breakpoints.l}px) {
    margin-top: 6px;
  }
`
export const ExternalLink = styled(GatsbyImage)`
  min-width: 14px;
  width: 14px;
  margin-left: 3px;
  margin-top: 3px;
`
